import React, { useEffect } from 'react';
import { setUtmSessionStorage } from './setUtmSessionStorage';

interface Props {
  search: string;
}

export function Utm({ search }: Props) {
  useEffect(() => {
    setUtmSessionStorage(search);
  }, []);

  return <></>;
}
